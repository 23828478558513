import { type VariantProps, cva } from 'class-variance-authority'
import React, { ForwardedRef } from 'react'
import { twMerge } from 'tailwind-merge'

// The class variance can be easily extended https://cva.style/docs
const buttonClassVariance = cva(
  'relative group disabled:opacity-60 transition-colors duration-200 rounded-full',
  {
    variants: {
      variant: {
        primary: 'bg-blue text-white overflow-clip',
        secondary:
          'hover:brightness-125 bg-gray-775 disabled:hover:brightness-100 ' + // Normal
          'bright:bg-transparent bright:text-blue bright:border bright:border-blue bright:hover:brightness-75 ' + // Bright
          'light:bg-transparent light:text-blue light:border light:border-blue light:hover:brightness-75 ', // LIght
        black:
          'bg-black hover:bg-gray-800 hover:brightness-125 disabled:hover:brightness-100',
        ghost:
          'bg-transparent text-blue hover:underline border-blue underline-offset-2',
      },
      size: {
        medium: 'py-3 px-7',
        icon: 'w-12 h-12',
      },
    },

    defaultVariants: {
      variant: 'primary',
      size: 'medium',
    },
  },
)
type ButtonType = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonClassVariance>
export interface ButtonProps extends ButtonType {
  children: React.ReactNode
  submitButton?: boolean
  disabled?: boolean
  contentClassName?: string
}

const Button = (
  {
    children,
    variant = 'primary',
    size,
    submitButton,
    disabled = false,
    className,
    contentClassName,
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement>,
) => {
  const clsname = twMerge(
    buttonClassVariance({ variant: variant, size: size }),
    className,
  )

  const content = (
    <>
      <span className={twMerge('relative z-10', contentClassName)}>
        {children}
      </span>
      {variant === 'primary' && (
        <span className="absolute left-0 rounded-full h-0 group-hover:h-96 w-full group-disabled:h-0 bg-blue-hover translate-y-9 group-hover:-translate-y-1/2 transition-transform duration-500" />
      )}
    </>
  )

  return (
    <button
      ref={ref}
      type={submitButton ? 'submit' : 'button'}
      className={clsname}
      disabled={disabled}
      {...props}
    >
      {content}
    </button>
  )
}

export default React.forwardRef(Button)
