import { useCallback, useEffect, useState } from 'react'

type Locale<T> = {
  locale: string
  translations: T
  changeLocale: (locale: 'nl' | 'en') => void
}

export type LocalisedMessages<T> = {
  en: T
  nl: T
}

function useLocale<T>(messages?: LocalisedMessages<T>): Locale<T> {
  const [locale, setLocale] = useState('nl')
  const [translations, setTranslations] = useState<T>(messages?.[locale])

  const setBrowserLocale = useCallback(
    (locale: 'nl' | 'en') => {
      localStorage.setItem('locale', locale)
      setLocale(locale)
      setTranslations(messages?.[locale])
    },
    [messages],
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    // Read local from query params, local storage or default to browser locale
    const locale =
      params.get('lng') || localStorage.getItem('locale') || navigator.language

    const normalisedLocale = locale.match(/^nl/g) ? 'nl' : 'en'

    setBrowserLocale(normalisedLocale)
  }, [setBrowserLocale])

  /**
   * Function to change the locale by an action of the user.
   *
   * performs reload of the page with the new locale
   */
  const changeLocale = useCallback((locale: 'nl' | 'en') => {
    const params = new URLSearchParams(window.location.search)
    params.set('lng', locale)

    window.location.search = params.toString()
  }, [])

  return { locale, translations, changeLocale }
}

export { useLocale }
