import { useContext, useState, createContext } from 'react'

export type Theme =
  | 'black'
  | 'light'
  | 'bright'
  | 'blue'
  | 'transp-light'
  | 'transp-black'
  | 'transp-blue'

type ContextType = {
  setTheme: (arg0: Theme) => void
  theme: Theme
  switchOffset: number
  setSwitchOffset: (arg0: number) => void
}

const defaultContextValues: ContextType = {
  setTheme: () => {
    return
  },
  theme: 'black',
  switchOffset: 0,
  setSwitchOffset: (_x) => {
    return
  },
}

const ThemeContext = createContext<ContextType>(defaultContextValues)

type ProviderProps = {
  children: React.ReactNode
}

export const ThemeProvider = ({ children }: ProviderProps) => {
  const [theme, setTheme] = useState<Theme>('black')
  const [switchOffset, setSwitchOffset] = useState<number>(0)

  return (
    <ThemeContext.Provider
      value={{ theme, setTheme, switchOffset, setSwitchOffset }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext)
