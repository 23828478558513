import '../styles/globals.css'
import { ThemeProvider } from '../hooks/useTheme'
// Manually import Font Awesome css to avoid having oversized icons when the
// icon is rendered before the css loads. It also keeps Font Asweome from trying
// to load the css twice.
// (source: https://fontawesome.com/v6/docs/web/use-with/react/use-with#next-js)
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import Script from 'next/script'
import { usePathname } from 'next/navigation'
import Head from 'next/head'
config.autoAddCss = false

import localFont from 'next/font/local'
import ResearchableCookieBanner from '@/organisms/ResearchableCookieBanner'
const tegFont = localFont({
  src: [
    {
      path: '../public/fonts/TEG/TEG-ExtraLight.otf',
      weight: '200',
      style: 'normal',
    },
    {
      path: '../public/fonts/TEG/TEG-Light.otf',
      weight: '300',
      style: 'normal',
    },
    {
      path: '../public/fonts/TEG/TEG-Regular.otf',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/TEG/TEG-Medium.otf',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/fonts/TEG/TEG-Bold.otf',
      weight: '700',
      style: 'normal',
    },
    {
      path: '../public/fonts/TEG/TEG-Black.otf',
      weight: '900',
      style: 'normal',
    },
  ],
  display: 'block',
  variable: '--font-teg',
})

function MyApp({ Component, pageProps }) {
  const path = usePathname()

  return (
    <>
      <Head>
        <link rel="canonical" href={`https://researchable.nl${path}`} />
        <meta property="og:url" content={`https://researchable.nl${path}`} />
      </Head>
      <ThemeProvider>
        <main className={`${tegFont.variable} font-sans`}>
          <ResearchableCookieBanner />
          <Component {...pageProps} />
        </main>
      </ThemeProvider>
      {/*
      beforeInteractive is added so that the script gets included in server-side rendered HTML. This way, netlify
      edge functions can inject a nonce into it, allowing it to be executed.
      */}
      <Script
        async
        strategy="beforeInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-SK8G0PPS50"
        defer
      />
      <Script id="google-analytics" strategy="beforeInteractive" async defer>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-SK8G0PPS50');

          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });
        `}
      </Script>
    </>
  )
}

export default MyApp
