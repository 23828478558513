import { useCallback, useRef } from 'react'
import { Theme, useTheme } from '../hooks/useTheme'

type Props = {
  theme: Theme
  children: React.ReactNode
}

export const getThemeClass = (theme: Theme): string => {
  switch (theme) {
    case 'black':
      return 'theme-black'
    case 'light':
      return 'theme-light'
    case 'bright':
      return 'theme-bright'
    case 'blue':
      return 'theme-blue'
    case 'transp-light':
      return 'theme-transp-light'
    case 'transp-black':
      return 'theme-transp-black'
    case 'transp-blue':
      return 'theme-transp-blue'
  }
}

const ThemeBox = ({ theme: myTheme, children }: Props) => {
  const ref = useRef<HTMLDivElement>()
  const themeClass = getThemeClass(myTheme)

  const { theme: activeTheme, setTheme, switchOffset } = useTheme()

  // this useEffect registers a scroll listener for every "theme section" on a page
  // so for many themes this can become quite resource intensive. a potential future
  // work solution may be to pass the bounding box once (after first render) to the
  // AdaptiveThemeBox, and maintain a list of transition points where the theme
  // should switch. This would massively break if the bounding box changes because
  // elements were added/removed, but it would only require 1 scroll listener.

  const eventOpts: AddEventListenerOptions & EventListenerOptions = {
    passive: true,
  }

  const listener = () => {
    const { top, bottom } = ref.current.getBoundingClientRect()
    if (
      top <= switchOffset &&
      switchOffset < bottom &&
      myTheme !== activeTheme
    ) {
      // this element is currently occupying the top of the screen
      setTheme(myTheme)
    }
  }

  const setRef = useCallback(
    (node) => {
      if (ref.current) {
        window.removeEventListener('scroll', listener, eventOpts)
      }

      if (node) {
        window.addEventListener('scroll', listener, eventOpts)
      }

      ref.current = node
    },
    [eventOpts, listener],
  )

  return (
    <div ref={setRef} className={themeClass}>
      {children}
    </div>
  )
}

export default ThemeBox
