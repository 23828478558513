import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type VariantProps, cva } from 'class-variance-authority'
import NextLink from 'next/link'
import { twMerge } from 'tailwind-merge'
import ArrowUpRight from './ArrowUpRight'

// The class variance can be easily extended https://cva.style/docs
const linkClassVariance = cva(
  'disabled:opacity-60 transition-colors duration-200',
  {
    variants: {
      variant: {
        primary:
          'py-2 lg:py-1 pl-4 text-left bg-transparent black:text-white light:text-black bright:text-blue',
        secondary:
          'py-2 lg:py-1 px-4 black:bg-white/10 black:hover:bg-white/20 light:text-black black:text-white justify-between  flex-row-reverse',
        plain: '',
        gray: 'hover:text-white transition-colors duration-200 text-white/60',
      },
      icon: {
        on: 'relative flex items-center gap-4 group py-3 px-7 overflow-hidden',
        off: '',
      },
      indent: {
        default: '',
        off: 'pl-0',
      },
      underline: {
        animate: [
          'relative',
          'after:absolute after:h-px after:w-0 after:bottom-0 after:left-0',
          'hover:after:w-full after:transition-all after:duration-200',
          // the bgcolor of the underline must be provided with the className
        ],
        on: ['relative underline'],
        off: '',
      },
    },

    defaultVariants: {
      variant: 'primary',
      icon: 'on',
      indent: 'default',
      underline: 'off',
    },
  },
)
type LinkType = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof linkClassVariance>
export interface LinkProps extends LinkType {
  children: React.ReactNode
  href: string
}

const Link = ({
  children,
  variant = 'primary',
  icon = 'on',
  indent,
  underline,
  href,
  className,
}: LinkProps) => {
  const clsname = twMerge(
    className,
    linkClassVariance({
      variant: variant,
      indent: indent,
      icon: icon,
      underline: underline,
    }),
  )

  const isExternalLink = href.startsWith('http')

  const getIcon = isExternalLink ? (
    <ArrowUpRight />
  ) : (
    <FontAwesomeIcon
      className={
        'h-4 text-blue group-hover:transform group-hover:translate-x-1 transition duration-300'
      }
      icon={faAngleRight}
    />
  )

  const content = (
    <>
      {icon == 'on' && getIcon}
      {children}
    </>
  )

  const maybeExternalLinkProps = () => {
    if (isExternalLink) {
      return {
        target: '_blank',
        // this is required to fix a vulnerability where a malicious website may access contents of the previous tab
        rel: 'noreferrer'
      }
    }

    return {
      target: '_self'
    }
  }

  return (
    <NextLink
      className={clsname}
      href={href}
      {...maybeExternalLinkProps()}
    >
      {content}
    </NextLink>
  )
}

export default Link
