import { useRef, useEffect } from 'react'
import { useTheme, Theme } from '../hooks/useTheme'
import { getThemeClass } from './ThemeBox'

type Props = {
  children: React.ReactNode
  defaultTheme: Theme
}

const AdaptiveThemeBox = ({ children, defaultTheme }: Props) => {
  const ref = useRef<HTMLDivElement>()
  const { theme, setSwitchOffset, setTheme } = useTheme()
  const themeclass = getThemeClass(theme)

  // when the component renders, trigger a scroll event which will cause
  // the scroll listeners to set the correct theme color.
  useEffect(() => {
    setTheme(defaultTheme)
  }, [])

  // set the height of the header as the theme switch offset, meaning the theme of
  // the header switches when the bottom of the header touches the next theme.
  // this will only set the offset once, meaning it will not change when the screen
  // size changes. this will not typically happen, except on mobile devices with screen
  // rotation maybe, when the header switches from sm to md.
  const callback = () => {
    // we get the first child of this element and look at its height, because
    // for some reason the height of this component never changes
    const header = ref.current.children[0]

    if (!header) {
      return
    }

    setSwitchOffset(header.getBoundingClientRect().height)
  }

  useEffect(() => {
    if (!ref.current) {
      return
    }

    // we listen on the transitionend event which is when the new height of the
    // header is determined. when the height is known we set the switchoffset
    // point.
    ref.current.addEventListener('transitionend', callback)

    // call the callback when the page initialises first
    callback()

    return () => {
      if (!ref.current) {
        return
      }

      ref.current.removeEventListener('transitionend', callback)
    }
  }, [ref.current])

  return (
    <div ref={ref} className={themeclass}>
      {children}
    </div>
  )
}

export default AdaptiveThemeBox
