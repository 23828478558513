import Button from '@/atoms/Button'
import Link from '@/atoms/Link'
import { useEffect, useRef } from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { hotjar } from 'react-hotjar'
import { attributes } from '../content/cookie_banner.md'
import { useLocale } from '@/hooks/useLocale'
import { CookieBanner } from '@/content/types'
import AdaptiveThemeBox from '@/atoms/AdaptiveThemeBox'

const ResearchableCookieBanner = () => {
  const { translations } = useLocale<CookieBanner>(attributes)

  const allConsentGranted = () => {
    // Google analytics consent
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      analytics_storage: 'granted',
    })

    // Initialize Hotjar
    hotjar.initialize(2122156, 1)
  }

  /**
   * Check if the user has already given consent to cookies. If so, grant all
   */
  useEffect(() => {
    const cookies = Cookies.get()
    if (cookies['cookie-consent'] === 'true') {
      allConsentGranted()
    }
  }, [])

  const indexOfPrivacyPolicy = translations.statement
    .toLowerCase()
    .indexOf('privacy policy')

  const cookieConsentRef = useRef<CookieConsent | null>(null)

  return (
    <AdaptiveThemeBox defaultTheme={'light'}>
      <CookieConsent
        disableStyles
        ref={cookieConsentRef}
        cookieName="cookie-consent"
        onAccept={allConsentGranted}
        buttonWrapperClasses="hidden"
      >
        <div className="fixed bottom-0 py-6 transp-light:bg-black  bg-white z-50 px-12 w-full flex gap-6 md:gap-20 md:justify-between flex-col md:flex-row light:text-white light:bg-black bright:bg-black">
          <p className="flex-wrap my-auto transp-light:text-white text-black bright:text-white transp-black:text-black transp-blue:text-white light:text-white">
            {/* Replace the privacy policy mentioned in the CMS with a link */}
            {translations.statement.slice(0, indexOfPrivacyPolicy)}
            <Link
              href="/privacy-statement"
              underline="on"
              indent="off"
              icon="off"
            >
              <span className="text-blue">
                {translations.privacy_policy_link_text}
              </span>
            </Link>
            {translations.statement.slice(
              indexOfPrivacyPolicy + 'privacy policy'.length,
            )}
          </p>
          <div className="flex gap-2 md:w-fit w-full justify-between">
            <Button
              onClick={() => cookieConsentRef.current?.decline()}
              variant="ghost"
            >
              {translations.decline}
            </Button>
            <Button onClick={() => cookieConsentRef.current?.accept()}>
              {translations.accept}
            </Button>
          </div>
        </div>
      </CookieConsent>
    </AdaptiveThemeBox>
  )
}

export default ResearchableCookieBanner
