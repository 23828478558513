const ArrowUpRight = () => {
  return (
    <i className="flex-none h-2.5 w-2.5 text-blue box-border group relative overflow-hidden">
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="group-hover:-translate-y-full group-hover:translate-x-full transition-transform duration-300"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.21995 9.78C0.360576 9.92045 0.5512 9.99934 0.74995 9.99934C0.948701 9.99934 1.13933 9.92045 1.27995 9.78L8.49995 2.56V8.25C8.49995 8.44891 8.57897 8.63968 8.71962 8.78033C8.86027 8.92098 9.05104 9 9.24995 9C9.44886 9 9.63963 8.92098 9.78028 8.78033C9.92093 8.63968 9.99995 8.44891 9.99995 8.25V0.75C9.99995 0.551088 9.92093 0.360322 9.78028 0.21967C9.63963 0.0790175 9.44886 0 9.24995 0H1.74995C1.55104 0 1.36027 0.0790175 1.21962 0.21967C1.07897 0.360322 0.99995 0.551088 0.99995 0.75C0.99995 0.948912 1.07897 1.13968 1.21962 1.28033C1.36027 1.42098 1.55104 1.5 1.74995 1.5H7.43995L0.21995 8.72C0.0794997 8.86063 0.000610352 9.05125 0.000610352 9.25C0.000610352 9.44875 0.0794997 9.63937 0.21995 9.78Z"
          fill="#00B1E9"
        />
      </svg>
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="-translate-x-full group-hover:translate-x-0 group-hover:-translate-y-full transition-transform duration-300 delay-75"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.21995 9.78C0.360576 9.92045 0.5512 9.99934 0.74995 9.99934C0.948701 9.99934 1.13933 9.92045 1.27995 9.78L8.49995 2.56V8.25C8.49995 8.44891 8.57897 8.63968 8.71962 8.78033C8.86027 8.92098 9.05104 9 9.24995 9C9.44886 9 9.63963 8.92098 9.78028 8.78033C9.92093 8.63968 9.99995 8.44891 9.99995 8.25V0.75C9.99995 0.551088 9.92093 0.360322 9.78028 0.21967C9.63963 0.0790175 9.44886 0 9.24995 0H1.74995C1.55104 0 1.36027 0.0790175 1.21962 0.21967C1.07897 0.360322 0.99995 0.551088 0.99995 0.75C0.99995 0.948912 1.07897 1.13968 1.21962 1.28033C1.36027 1.42098 1.55104 1.5 1.74995 1.5H7.43995L0.21995 8.72C0.0794997 8.86063 0.000610352 9.05125 0.000610352 9.25C0.000610352 9.44875 0.0794997 9.63937 0.21995 9.78Z"
          fill="#00B1E9"
        />
      </svg>
    </i>
  )
}

export default ArrowUpRight
